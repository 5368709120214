import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/lexical-scope",
  "date": "2014-07-06",
  "title": "LEXICAL SCOPE",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Lexical scope means that scope is defined by author-time decisions of where functions are declared. The lexing phase of compilation is essentially able to know where and how all identifiers are declared, and predict how they will be looked up during execution."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Before getting into more details, let's have a look how JavaScript engine works when it comes to compilation.
Here is a breakdown of the parts:`}</p>
    <h3>{`Engine`}</h3>
    <p>{`Responsible for start-to-finish compilation and execution of our JavaScript program.`}</p>
    <h3>{`Compiler`}</h3>
    <p>{`One of Engine’s friends; handles all the dirty work of parsing and code-generation`}</p>
    <h3>{`Scope`}</h3>
    <p>{`Another friend of Engine; collects and maintains a look-up list of
all the declared identifiers (variables), and enforces a strict set of
rules as to how these are accessible to currently executing code.`}</p>
    <p>{`Take the following statement:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var a = 2;
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Encountering var a, Compiler asks Scope to see if a variable a
already exists for that particular scope collection. If so, Compiler
ignores this declaration and moves on. Otherwise, Compiler asks
Scope to declare a new variable called a for that scope collection.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Compiler then produces code for Engine to later execute, to handle
the a = 2 assignment. The code Engine runs will first ask Scope if there is a variable called `}<strong parentName="p">{`a`}</strong>{` accessible in the current scope collection.
If so, Engine uses that variable. If not, Engine looks elsewhere.
If Engine eventually finds `}<strong parentName="p">{`a`}</strong>{` variable, it assigns the value 2 to it.
If not, Engine will raise its hand and yell out an error!`}</p>
      </li>
    </ul>
    <p>{`An LHS look-up is done when a variable appears on
the lefthand side of an assignment operation and an RHS look-up is
done when a variable appears on the righthand side of an assignment
operation.`}</p>
    <p>{`Two mechanisms in JavaScript can `}<strong parentName="p">{`cheat`}</strong>{` lexical scope: `}<strong parentName="p">{`eval(..)`}</strong>{` and
`}<strong parentName="p">{`with`}</strong>{`. The former can modify existing lexical scope (at runtime) by
evaluating a string of `}<strong parentName="p">{`code`}</strong>{` that has one or more declarations in it.
The latter essentially creates a whole new lexical scope (again, at runtime)
by creating an object reference as a scope and that object’s properties
as scoped identifiers.
There are two predominant models for how scope works. The first of
these is by far the most common, used by the vast majority of programming
languages. It’s called the lexical scope, and we will examine it in
depth. The other model, which is still used by some languages (such
as Bash scripting, some modes in Perl, etc) is called dynamic scope.`}</p>
    <h2>{`Lex-time`}</h2>
    <p>{`The first traditional phase of a standard
language compiler is called lexing (a.k.a., tokenizing). If you recall, the
lexing process examines a string of source code characters and assigns
semantic meaning to the tokens as a result of some stateful parsing.
It is this concept that provides the foundation to understand what
lexical scope is and where the name comes from.
To define it somewhat circularly, lexical scope is scope that is defined
at lexing time. In other words, lexical scope is based on where variables
and blocks of scope are authored, by you, at write time, and thus is
(mostly) set in stone by the time the lexer processes your code.
We will see in a little bit that there are some ways to cheat lexical
scope, thereby modifying it after the lexer has passed by, but
these are frowned upon. It is considered best practice to treat
lexical scope as, in fact, lexical-only.`}</p>
    <p>{`Let’s consider this block of code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function foo(a) {
    var b = a * 2;
    function bar(c) {
        console.log( a, b, c );
    }
    bar( b * 3 );
}
foo( 2 ); // 2, 4, 12
`}</code></pre>
    <p>{`There are three nested scopes inherent in this code example. It may be
helpful to think about these scopes as bubbles inside of each other.`}</p>
    <p>{`Bubble 1 encompasses the global scope and has just one identifier in
it: foo.`}</p>
    <p>{`Bubble 2 encompasses the scope of foo, which includes the three
identifiers: a, bar, and b.`}</p>
    <p>{`Bubble 3 encompasses the scope of bar, and it includes just one identifier:
c.`}</p>
    <p>{`Scope bubbles are defined by where the blocks of scope are written,
which one is nested inside the other, etc. The bubble for bar is entirely contained within the bubble for foo, because (and only because) that’s where we chose to define the function
bar.`}</p>
    <p>{`Notice that these nested bubbles are strictly nested. We’re not talking
about Venn diagrams where the bubbles can cross boundaries. In other
words, no bubble for some function can simultaneously exist (partially)
inside two other outer scope bubbles, just as no function can
partially be inside each of two-parent functions.	`}</p>
    <h2>{`Lookups`}</h2>
    <p>{`The structure and relative placement of these scope bubbles fully explains
to the engine all the places it needs to look to find an identifier.`}</p>
    <p>{`In the previous code snippet, the engine executes the con
sole.log(..) statement and goes looking for the three referenced
variables a, b, and c. It first starts with the innermost scope bubble, the
scope of the bar(..) function. It won’t find a there, so it goes up one
level, out to the next nearest scope bubble, the scope of foo(..). It
finds a there, and so it uses that a. Same thing for b. But c, it does find
inside of bar(..).`}</p>
    <p>{`Had there been a c both inside of bar(..) and inside of foo(..), the
console.log(..) statement would have found and used the one in
bar(..), never getting to the one in foo(..).`}</p>
    <p>{`Scope look-up stops once it finds the first match. The same identifier
name can be specified at multiple layers of nested scope, which is called
`}<strong parentName="p">{`shadowing`}</strong>{` (the inner identifier `}<strong parentName="p">{`shadows`}</strong>{` the outer identifier). Regardless
of shadowing, scope look-up always starts at the innermost
scope being executed at the time, and works its way outward/upward
until the first match, and stops.`}</p>
    <p>{`Global variables are automatically also properties of the global
object (window in browsers, etc.), so it is possible to reference
a global variable not directly by its lexical name, but instead
indirectly as a property reference of the global object.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`window.a
`}</code></pre>
    <p>{`This technique gives access to a global variable that would
otherwise be inaccessible due to it being shadowed. However,
non-global shadowed variables cannot be accessed.`}</p>
    <p>{`No matter where a function is invoked from, or even how it is invoked,
its lexical scope is only defined by where the function was declared.
The lexical scope look-up process only applies to first-class identifiers,
such as the a, b, and c. If you had a reference to foo.bar.baz in a piece
of code, the lexical scope look-up would apply to finding the foo
identifier, but once it locates that variable, object property-access rules
take over to resolve the bar and baz properties, respectively.`}</p>
    <h2>{`Cheating Lexical`}</h2>
    <p>{`If lexical scope is defined only by where a function is declared, which
is entirely an author-time decision, how could there possibly be a chance
to `}<strong parentName="p">{`modify`}</strong>{` (a.k.a., cheat) lexical scope at runtime?`}</p>
    <p>{`JavaScript has two such mechanisms. Both of them are equally
frowned upon in the wider community as bad practices to use in your
code. But the typical arguments against them are often missing the
most important point: `}<strong parentName="p">{`cheating lexical scope leads to poorer performance.`}</strong></p>
    <h3>{`eval`}</h3>
    <p>{`The eval(..) function in JavaScript takes a string as an argument and
treats the contents of the string as if it had been authored code
at that point in the program. In other words, you can programmatically
generate code inside of your authored code, and run the generated
code as if it had been there at author time.`}</p>
    <p>{`Evaluating eval(..) in that light, it should be clear
how eval(..) allows you to modify the lexical scope environment by cheating and pretending that author-time (a.k.a., lexical) code was
there all along.`}</p>
    <p>{`On subsequent lines of code after an eval(..) has executed, the engine
will not `}<strong parentName="p">{`know`}</strong>{` or `}<strong parentName="p">{`care`}</strong>{` that the previous code in question was dynamically
interpreted and thus modified the lexical scope
environment. The engine will simply perform its lexical scope lookups
as it always does.`}</p>
    <p>{`Consider the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function foo(str, a) {
    eval( str ); // cheating!
    console.log( a, b );
}
var b = 2;
foo( "var b = 3;", 1 ); // 1, 3
`}</code></pre>
    <p>{`The string "var b = 3;" is treated, at the point of the eval(..) call,
as code that was there all along. Because that code happens to declare
a new variable b, it modifies the existing lexical scope of foo(..).
As mentioned earlier, this code creates variable b inside
of foo(..) that shadows the b that was declared in the outer (global)
scope.`}</p>
    <p>{`When the console.log(..) call occurs, it finds both a and b in the
scope of foo(..), and never finds the outer b. Thus, we print out `}<strong parentName="p">{`1,
3`}</strong>{` instead of `}<strong parentName="p">{`1, 2`}</strong>{` as would have normally been the case.`}</p>
    <p>{`In this example, for simplicity sake, the string of `}<strong parentName="p">{`code`}</strong>{` we pass
in was a fixed literal. But it could easily have been programmatically
created by adding characters together based on your
program’s logic. eval(..) is usually used to execute dynamically
created code, as dynamically evaluating essentially static
code from a string literal would provide no real benefit to just
authoring the code directly.`}</p>
    <p>{`By default, if a string of code that eval(..) executes contains one or
more declarations (either variables or functions), this action modifies
the existing lexical scope in which the eval(..) resides. Technically,
eval(..) can be invoked indirectly, through various tricks (beyond
our discussion here), which causes it to instead execute in the context of the global scope, thus modifying it.
But in either case, eval(..) can at runtime modify an author-time lexical scope.`}</p>
    <p>{`eval(..) when used in a strict-mode program, operates in its
own lexical scope, which means declarations made inside of
the eval() do not actually modify the enclosing scope.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function foo(str) {
"use strict";
eval( str );
console.log( a ); // ReferenceError: a is not defined
}
foo( "var a = 2" );
`}</code></pre>
    <p>{`There are other facilities in JavaScript that amount to a very similar
effect to eval(..). setTimeout(..) and setInterval(..) can take a
string for their respective first argument, the contents of which are
evaluated as the code of a dynamically generated function. This is old,
legacy behaviour and long-since deprecated. Don’t do it!`}</p>
    <p>{`The new Function(..) function constructor similarly takes a string
of code in its last argument to turn into a dynamically generated function
(the first argument(s), if any, are the named parameters for the
new function). This function-constructor syntax is slightly safer than
eval(..), but it should still be avoided in your code.`}</p>
    <p>{`The use-cases for dynamically generating code inside your program
are incredibly rare, as the performance degradations are rarely
worth the capability.`}</p>
    <h2>{`with`}</h2>
    <p>{`The other frowned-upon (and now deprecated!) feature in JavaScript
that cheats lexical scope is the with keyword. Multiple valid
ways can be explained, but I will choose here to explain it
from the perspective of how it interacts with and affects lexical scope.`}</p>
    <p>{`with is typically explained as a shorthand for making multiple property
references against an object without repeating the object reference
itself each time.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var obj = {
    a: 1,
    b: 2,
};

// more "tedious" to repeat "obj"
obj.a = 2;
obj.b = 3;
obj.c = 4;

// "easier" short-hand
with (obj) {
    a = 3;
    b = 4;
    c = 5;
}
`}</code></pre>
    <p>{`However, there’s much more going on here than just a convenient
shorthand for object property access. Consider:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function foo(obj) {
    with (obj) {
        a = 2;
    }
}

var o1 = {
    a: 3
};

var o2 = {
    b: 3
};

foo( o1 );
console.log( o1.a ); // 2
foo( o2 );
console.log( o2.a ); // undefined
console.log( a ); // 2—Oops, leaked global!
`}</code></pre>
    <p>{`In this code example, two objects o1 and o2 are created. One has a
property, and the other does not. The foo(..) function takes an object
reference obj as an argument and calls with (obj) { .. } on the
reference. Inside the with block, we make what appears to be a normal
lexical reference to a variable a, an LHS reference in fact (see Chapter
1), to assign to it the value of 2.`}</p>
    <p>{`When we pass in o1, the = 2 assignment finds the property o1.a
and assigns it the value 2, as reflected in the subsequent con
sole.log(o1.a) statement. However, when we pass in o2 since it does not have a property, no such property is created, and o2.a remains
undefined.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      